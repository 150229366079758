import Home from '@/views/home'

export default [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { name: '全局配置', show: true, icon: 'el-icon-s-home', login: true },
  },
  {
    path: '/category',
    name: 'Category',
    component: () => import('@/views/category'),
    meta: {
      name: '分类管理',
      show: true,
      icon: 'el-icon-s-custom',
      login: true,
    },
  },
  {
    path: '/goods',
    name: 'Goods',
    component: () => import('@/views/goods'),
    meta: { name: '商品管理', show: false, login: true },
  },
  {
    path: '/goodsc',
    name: 'InsertGoods',
    component: () => import('@/views/goodsc'),
    meta: {
      name: '新增商品',
      show: false,
      icon: 'el-icon-s-custom',
      login: true,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login'),
    meta: { name: '用户登录', show: false, login: false },
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/404'),
    meta: { name: '抱歉,您访问的页面出错了', show: false, login: false },
  },
  {
    path: '*',
    redirect: '/404',
    meta: { name: '抱歉,您访问的页面出错了', show: false, login: false },
  },
]
