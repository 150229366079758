<template>
  <div id="app">
    <div class="root" v-if="$route.path != '/login'">
      <div class="left">
        <div class="left-content">
          <img
            class="left-content-logo"
            src="@/assets/common/logo_horizontal.png"
          />
          <el-menu :default-active="$route.path" :router="true">
            <div v-for="(v, k) in routes" :key="'routes_' + k">
              <el-menu-item :index="v.path" v-if="!v.children && v.meta.show">
                <i :class="v.meta.icon"></i>
                <span slot="title">{{ v.meta.name }}</span>
              </el-menu-item>
              <el-submenu :index="v.path" v-if="v.children && v.meta.show">
                <template slot="title">
                  <i :class="v.meta.icon"></i>
                  <span>{{ v.meta.name }}</span>
                </template>
                <el-menu-item-group>
                  <el-menu-item
                    :index="v.path + '/' + v1.path"
                    v-for="(v1, k1) in v.children"
                    :key="'routes_children_' + k1"
                    v-show="v1.meta.show"
                  >
                    {{ v1.meta.name }}
                  </el-menu-item>
                </el-menu-item-group>
              </el-submenu>
            </div>
          </el-menu>
        </div>
      </div>
      <div class="right">
        <div class="right-header">
          <div v-if="$route.path === '/'">
            <div class="el-page-header__content">全局配置</div>
          </div>
          <div v-else-if="$route.path === '/category'">
            <div class="el-page-header__content">分类管理</div>
          </div>
          <div v-else>
            <el-page-header
              @back="goBack"
              :content="$route.meta.name"
            ></el-page-header>
          </div>
        </div>
        <router-view class="router-view" />
      </div>
    </div>
    <router-view class="router-view" v-else />
  </div>
</template>

<script>
import routes from '@/router/routes'

export default {
  name: 'Root',
  components: {},
  mounted() {},
  created() {},
  data() {
    return {
      routes,
    }
  },
  computed: {},
  methods: {
    goBack() {
      this.$router.go(-1)
    },
  },
}
</script>

<style lang="scss">
@import '~@/theme/light/index.css';

html,
body,
#app {
  width: 100%;
  height: 100%;
}

#app {
  font-family: 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB',
    'Microsoft YaHei', '微软雅黑', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $def-bg-color;
  color: #333333;
}

.root {
  display: flex;
  height: 100%;

  .left::-webkit-scrollbar {
    display: none;
  }
  .left {
    width: $def-home-menu-width;
    height: 100%;
    overflow-y: scroll;

    .left-content {
      height: 100%;
      background-color: white;
      padding: $def-margin * 2;

      &-logo {
        width: 100%;
        padding: 40px;
      }

      .el-menu {
        border-right: 0;

        .el-submenu__title,
        .el-menu-item,
        .el-submenu {
          border-radius: $def-radius;
        }

        .el-submenu.is-active {
          color: $theme-color;
          background-color: $def-content-bg-color;
        }

        .el-menu-item.is-active {
          color: $theme-color;
          background-color: $def-content-bg-color;
          font-weight: 900;
        }

        .is-opened {
          background: none !important;
          .is-active {
            color: $theme-color;
            background-color: $def-content-bg-color;
            font-weight: 900;
          }
        }

        .el-submenu__title:hover,
        .el-menu-item:hover {
          background-color: $def-hover-bg-color;
          color: $theme-color !important;
        }
      }
    }
  }

  .right {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: $def-home-padding;

    .right-header {
      width: 100%;
      padding: $def-home-padding;
      background-color: #fff;
      margin-bottom: $def-home-padding;
      border-radius: $def-radius;
    }

    .menu-router {
      width: 100%;
      flex: 1;
      overflow: auto;
    }

    .router-view {
      overflow-y: scroll;
    }
  }
}
.person-menu {
  display: flex;
  flex-direction: column;
  .log-out {
    width: 100%;
    text-align: center;
    padding: $def-margin * 2 0;
  }
  .log-out:hover {
    border-radius: $def-radius;
    cursor: pointer;
    background-color: $def-hover-bg-color;
  }
}
</style>
