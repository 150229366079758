import axios from 'axios'

const request = axios.create({
baseURL: 'https://lzms.gfkjzjs.top/',
 //  baseURL: 'http://localhost:8889/',
  timeout: 5000,
})

request.interceptors.response.use((res) => {
  return res.data
})

export const UPLOAD_IMG_URL = 'https://lzms.gfkjzjs.top/upload/img'
export const UPLOAD_VIDEO_URL = 'https://lzms.gfkjzjs.top/upload/video'

export const reqGetAllConfig = () => request({ url: '/config', method: 'get' })
export const reqSaveAllConfig = (data) =>
  request({ url: '/config', method: 'post', data })

export const reqGetAllCategory = () =>
  request({ url: '/category', method: 'get' })
export const reqInsertCategory = (data) =>
  request({ url: '/category', method: 'post', data })
export const reqUpdateCategory = (data) =>
  request({ url: '/category', method: 'put', data })
export const reqDeleteCategory = (id) =>
  request({ url: `/category/${id}`, method: 'delete' })
export const reqMoveCategory = (data) =>
  request({ url: '/category/move', method: 'put', data })

export const reqGetAllGoods = () => request({ url: '/goods', method: 'get' })
export const reqInsertGoods = (data) =>
  request({ url: '/goods', method: 'post', data })
export const reqUpdateGoods = (data) =>
  request({ url: '/goods', method: 'put', data })
export const reqGetGoodsByID = (id) =>
  request({ url: `/category/${id}`, method: 'get' })

export const reqGetGoodsInfoByID = (id) =>
  request({ url: `/goods/${id}`, method: 'get' })

//同时删除多个商品
export const reqDeletesGoods = (data) =>
  request({ url: `/goods/deletes`, method: 'delete', data })
//获取所有的上架下架的产品
export const reqGetAllSXGoodsByCategoryID = (id) =>
  request({ url: `/category/sx/${id}`, method: 'get' })
//同时上架所有商品
export const reqShangJiaGoods = (data) =>
  request({ url: `/goods/shangjia`, method: 'put', data })
//同时下架所有商品
export const reqXiaJiaGoods = (data) =>
  request({ url: `/goods/xiajia`, method: 'put', data })
export const reqMoveGoods = (data) =>
  request({ url: '/goods/move', method: 'put', data })

export const reqSaveGoods = (data) =>
  request({ url: '/goods/save', method: 'put', data })
