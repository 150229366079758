<template>
  <div class="home">
    <section>
      <h3>首页背景</h3>
      <el-upload
        class="avatar-uploader"
        :action="UPLOAD_IMG_URL"
        :show-file-list="false"
        :on-success="homePageBgUploadSuccess"
        :before-upload="handleBeforeImgUpload"
      >
        <img v-if="homePageBg" :src="homePageBg" class="avatar" />
        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
      </el-upload>
    </section>
    <section>
      <h3>二级页面背景</h3>
      <el-upload
        class="avatar-uploader"
        :action="UPLOAD_IMG_URL"
        :show-file-list="false"
        :on-success="listPageBgUploadSuccess"
        :before-upload="handleBeforeImgUpload"
      >
        <img v-if="listPageBg" :src="listPageBg" class="avatar" />
        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
      </el-upload>
    </section>
    <section>
      <h3>首页标题</h3>
      <el-upload
        class="avatar-uploader"
        :action="UPLOAD_IMG_URL"
        :show-file-list="false"
        :on-success="homeTitleBgUploadSuccess"
        :before-upload="handleBeforeImgUpload"
      >
        <img v-if="homeTitleBg" :src="homeTitleBg" class="avatar" />
        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
      </el-upload>
    </section>
    <section>
      <h3>首页副标题</h3>
      <el-upload
        class="avatar-uploader"
        :action="UPLOAD_IMG_URL"
        :show-file-list="false"
        :on-success="homeSubtitleBgUploadSuccess"
        :before-upload="handleBeforeImgUpload"
      >
        <img v-if="homeSubtitleBg" :src="homeSubtitleBg" class="avatar" />
        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
      </el-upload>
    </section>
  </div>
</template>

<script>
import { UPLOAD_IMG_URL, reqGetAllConfig, reqSaveAllConfig } from '@/api'

export default {
  name: 'Home',
  components: {},
  data() {
    return {
      UPLOAD_IMG_URL: UPLOAD_IMG_URL,

      homeTitleBg: '',
      homeSubtitleBg: '',

      homePageBg: '',
      listPageBg: '',
    }
  },
  mounted() {
    reqGetAllConfig().then((res) => {
      this.homeTitleBg = res.data[0].homeTitleBg
      this.homeSubtitleBg = res.data[0].homeSubtitleBg

      this.homePageBg = res.data[0].homePageBg
      this.listPageBg = res.data[0].listPageBg
    })
  },
  methods: {
    //保存首页图片
    homePageBgUploadSuccess(res, file) {
      if (res.code === 20051) {
        this.homePageBg = res.data
        reqSaveAllConfig({
          homePageBg: this.homePageBg,
        })
          .then((r) => {
            this.$message.success(r.msg)
          })
          .catch((e) => {
            this.$message.error(err)
          })
      }
    },

    listPageBgUploadSuccess(res, file) {
      if (res.code === 20051) {
        this.listPageBg = res.data
        reqSaveAllConfig({
          listPageBg: this.listPageBg,
        })
          .then((r) => {
            this.$message.success(r.msg)
          })
          .catch((e) => {
            this.$message.error(err)
          })
      }
    },

    homeTitleBgUploadSuccess(res, file) {
      if (res.code === 20051) {
        this.homeTitleBg = res.data
        reqSaveAllConfig({
          homeTitleBg: this.homeTitleBg,
        })
          .then((r) => {
            this.$message.success(r.msg)
          })
          .catch((e) => {
            this.$message.error(err)
          })
      }
    },
    homeSubtitleBgUploadSuccess(res, file) {
      if (res.code === 20051) {
        this.homeSubtitleBg = res.data
        reqSaveAllConfig({
          homeSubtitleBg: this.homeSubtitleBg,
        })
          .then((r) => {
            this.$message.success(r.msg)
          })
          .catch((e) => {
            this.$message.error(err)
          })
      }
    },

    handleBeforeImgUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 10
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isLt2M
    },
  },
}
</script>

<style lang="scss">
.home {
  width: 100%;
  background-color: $def-content-bg-color;
  border-radius: $def-radius;
  padding: $def-margin * 4;
  position: relative;

  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    max-width: 200px;
    // width: 178px;
    // height: 178px;
    display: block;
  }
}
</style>
