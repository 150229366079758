/*-------------------------------------------node_modules-------------------------------------------*/
import Vue from 'vue'
Vue.config.productionTip = false
Vue.config.devtools = false
import ElementUI from 'element-ui'
import proportion from 'vue-proportion-directive'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)
Vue.use(proportion)
import uploader from 'vue-simple-uploader'
Vue.use(uploader)
/*-------------------------------------------node_modules-------------------------------------------*/

/*-------------------------------------------me_file-------------------------------------------*/
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
/*-------------------------------------------me_file-------------------------------------------*/

new Vue({
  router,
  store,
  render: (h) => h(App),
  mounted() {},
}).$mount('#app')
